import { defineStore } from 'pinia';

export default defineStore('profile', {
    state: () => ({
        email: '',
        emailVerified: false,
        id: '',
        name: '',
        type: '',
    }),
    getters: {
        getProfile: (state) => {
            return {
                email: state.email,
                emailVerified: state.emailVerified,
                id: state.id,
                name: state.name,
                type: state.type,
            };
        },
    },
    actions: {
        login(userData) {
            this.id = userData.id;
            this.name = userData.name;
        },
    },
});

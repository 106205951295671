import { callAPIGet, callAPIPost } from './network.js';
// import useProfileStore from '../stores/profile';

const LOCALSTORAGE_USER_DATA = 'JDD_SKBN_USR_DATA';
//TODO pinia problema de inicilización
// const profileStore = useProfileStore()

class Auth
{
    async getCurrentUser()
    {
        let userData = {};
        await callAPIGet('/auth/user').then((response) => {
            if (! response.ok || response.status === 204) {
                return {};
            }

            return response.json();
        }).then((jsonBody) => {
            userData = jsonBody;
        }).catch(() => {
        })

        return userData;
    }

    manageUser() {
        let userData = localStorage.getItem(LOCALSTORAGE_USER_DATA);
        if (userData == null) {
            callAPIPost(`/sokoban/users`, {type: 'guest'})
                .then((response) => response.json())
                .then((data) => {
                    // profileStore.email = data.email
                    // profileStore.emailVerified = data.emailVerified
                    // profileStore.id = data.id
                    // profileStore.name = data.name
                    // profileStore.type = data.type

                    localStorage.setItem(
                        LOCALSTORAGE_USER_DATA,
                        JSON.stringify({
                            email: data.email,
                            emailVerified: data.emailVerified,
                            id: data.id,
                            name: data.name,
                            type: data.type,
                        })
                    );
                })
                .catch((error) => console.log(error));
        } else {
            callAPIGet(`/sokoban/users/${JSON.parse(userData).id}`)
                .then((response) => response.json())
                .then((data) => {
                    localStorage.setItem(
                        LOCALSTORAGE_USER_DATA,
                        JSON.stringify({
                            email: data.email,
                            emailVerified: data.emailVerified,
                            id: data.id,
                            name: data.name,
                            type: data.type,
                        })
                    );
                })
                .catch((error) => console.log(error));
        }
    }
}

export default Auth;

import { defineStore } from 'pinia';

export const useSokobanStore = defineStore({
    id: 'sokoban',
    state: () => ({
        jsonLevel: null,
    }),
    getters: {
        getJsonLevel(state) {
            return state.jsonLevel;
        },
    },
    actions: {
        updateJsonLevel(level) {
            this.jsonLevel = level;
        },
    },
    persist: true,
});

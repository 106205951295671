<template>
  <div>
    <h1>LEVEL EDITOR</h1>
    <button @click="switchMode">{{ editMode ? 'Jugar' : 'Editar' }}</button>
    <span v-if="errorMessageTop" class="danger">{{ errorMessageTop }}</span>
    <Sokoban v-if="!editMode" :showControls="true" :level="jsLevel" :saveResult="false"
             @successfullyCompleted="handleSuccessfullyCompleted"/>
    <template v-if="editMode">
      <div class="row" v-for="(row, yIndex) in jsLevel" :key="yIndex">
        <div
            class="cell"
            @click="update(xIndex, yIndex)"
            v-for="(cell, xIndex) in row"
            :key="xIndex"
            :class="{
                        player: cell === 'P',
                        box: cell === 'B',
                        target: cell === 'T', //If a box was originally on a target cell
                        wall: cell === 'W',
                        done: cell === 'D',
                        void: cell === '.',
                      }"
        ></div>
      </div>

      <button @click="rotate">Girar</button>

      <hr/>

      <div class="selectors">
        <div class="row">
          <div
              @click="select('.')"
              class="cell wide dot"
              :class="activeCell === '.' ? 'active' : ''"
          >
            &nbsp;
          </div>
        </div>
        <div class="row">
          <div
              @click="select('W')"
              class="cell wall"
              :class="activeCell === 'W' ? 'active' : ''"
          >
            &nbsp;
          </div>
          <div
              @click="select(' ')"
              class="cell"
              :class="activeCell === ' ' ? 'active' : ''"
          >
            &nbsp;
          </div>
          <div
              @click="select('P')"
              class="cell player"
              :class="activeCell === 'P' ? 'active' : ''"
          >
            &nbsp;
          </div>
        </div>
        <div class="row">
          <div
              @click="select('B')"
              class="cell box"
              :class="activeCell === 'B' ? 'active' : ''"
          >
            &nbsp;
          </div>
          <div
              @click="select('T')"
              class="cell target"
              :class="activeCell === 'T' ? 'active' : ''"
          >
            &nbsp;
          </div>
          <div
              @click="select('D')"
              class="cell done"
              :class="activeCell === 'D' ? 'active' : ''"
          >
            &nbsp;
          </div>
        </div>
      </div>

      <div v-if="errorMessageMiddle" class="danger">{{ errorMessageMiddle }}</div>
      <label>level :</label>
      <textarea rows="12" cols="48" v-model="jsonLevel"></textarea>
      <button @click="resetLevel">Reiniciar</button>
      <!-- <button @click="applyLevel" :disabled="!validJSON">{{ buttonText }}</button> -->
    </template>

  </div>
</template>

<script>
import Sokoban from './Sokoban.vue';
import {useSokobanStore} from '../stores/sokoban.js';

export default {
    props: {
        // This is set when the parent passes in "v-model".
        modelValue: {
            type: Array,
            required: true,
        },
    },
    components: {
        Sokoban,
    },
    name: 'SokobanEditor',
    data() {
        return {
            errorMessageTop: '',
            errorMessageMiddle: '',
            defaultLevel: null, // This is stored as a JSON
            jsLevel: null, // This is going to be stored as an Array
            jsonLevel: null, // This is stored as a JSON
            validJSON: true,
            activeCell: '.',
            editMode: true,
        };
    },
    setup() {
        const sokobanStore = useSokobanStore();
        return {
            sokobanStore,
        };
    },
    beforeMount() {
        this.defaultLevel = this.formatJson(JSON.stringify(this.modelValue));
        this.jsonLevel = this.sokobanStore.jsonLevel
            || this.formatJson(JSON.stringify(this.jsLevel))
            || this.defaultLevel;
        this.jsLevel = JSON.parse(this.jsonLevel);
    },
    afterMount() {
    },
    computed: {
        // gives access to this.doubleCount inside the component
        // same as reading from store.doubleCount
        //...mapState(useSokoban, ['getLevel']),
        // same as above but registers it as this.myOwnName
        // ...mapState(useSokobanStore, {
        //     level: 'getLevel',
        // }),
        buttonText() {
            return this.validJSON ? 'Apply' : 'Invalid JSON';
        },
    },
    watch: {
        jsonLevel(value) {
            // Every time the JSON Level changes, we reset the successful completion to false.
            this.$emit('successfullyCompleted', false);
            this.jsonLevel = this.formatJson(value);

            try {
                this.jsLevel = JSON.parse(value);
                this.applyLevel();

                this.$emit('update:modelValue', this.jsLevel);
            } catch (Error) {
                this.validJSON = false;
            }
        },
        validJSON(value) {
            this.errorMessageMiddle = value === false
                ? 'Invalid JSON'
                : '';
        }
    },
    methods: {
        rotate() {
          let rotatedLevel = [];
          let column = this.jsLevel.length - 1;
          for (let i = 0; i < this.jsLevel.length; i++) {
            let currentRow = this.jsLevel[i];

            for (let j = 0; j < currentRow.length; j++) {
              let currentCol = currentRow[j];

              if (rotatedLevel[j] === undefined) {
                rotatedLevel[j] = [];
              }

              rotatedLevel[j][column] = currentCol;
            }

            column--;
          }

          this.jsonLevel = this.formatJson(JSON.stringify(rotatedLevel));
        },
        handleSuccessfullyCompleted(hasCompletedSuccessfully) {
            this.$emit('successfullyCompleted', hasCompletedSuccessfully);
        },
        resetLevel() {
            this.jsonLevel = this.defaultLevel;
        },
        formatJson(value) {
            //Validate the JSON
            try {
                JSON.parse(value);
                this.validJSON = true;
            } catch (error) {
                this.validJSON = false;
            }

            return value
                //Format JSON to display it better in the textarea
                //eslint-disable-next-line
                .replace(/([\[\]])([\[\]])/g, '$1\n    $2') // Double open bracket moves it to new line
                .replace(/,\[/g, ',\n    [') // Open bracket after comma goes to new line
                .replace(/\n\s+]$/, '\n]') // Don't add spaces to the last closing bracket
                .replace(/'/, '"')
                .replace(/(])[^\]]*$/, '$1') //Remove any trailing character after the last closing bracket
                .replace(/'([^'])'/g, '"$1"') //Replace single quotes with double quotes, just because
                //eslint-disable-next-line
                .replace(/(]),(\n*\s*[^\[]*])/, '$1$2'); //Remove last trailing comma
        },
        switchMode() {
            if (this.findPlayer() === null) {
                this.errorMessageTop = 'No hay jugador en el tablero. Por favor, coloca el jugador (la caja azul).';
                return;
            } else if (! this.validJSON) {
                this.errorMessageTop = 'Por favor, arregla los errores de JSON antes de jugar.';
                return;
            }

            this.errorMessageTop = '';
            this.editMode = ! this.editMode;
        },
        applyLevel() {
            this.sokobanStore.updateJsonLevel(this.jsonLevel);
        },
        select(cell) {
            if (this.activeCell === cell) {
                this.activeCell = '.';
                return;
            }
            this.activeCell = cell;
        },
        update(x, y) {
            if (this.activeCell === 'P') {
                const playerPosition = this.findPlayer();

                // If there was already a player on the board, remove the previous position
                if (playerPosition) {
                    this.jsLevel[playerPosition.y][playerPosition.x] = ' ';
                }

                this.errorMessageTop = '';
            }

            this.jsLevel[y][x] = this.activeCell;
            this.jsonLevel = JSON.stringify(this.jsLevel);
        },
        findPlayer() {
            for (let y = 0; y < this.jsLevel.length; y++) {
                for (let x = 0; x < this.jsLevel[y].length; x++) {
                    if (this.jsLevel[y][x] === 'P') {
                        return {x, y};
                    }
                }
            }
            return null; // player not found
        },
    },
};
</script>

<style scoped>
.editor {
    border: 3px solid black !important;
}

.selectors .cell {
    margin: 0.5rem;
}

.selectors .cell:hover {
    cursor: grab;
}

.cell:hover {
    cursor: pointer;
}

.dot {
    background: repeating-linear-gradient(
            45deg,
            red,
            red 8px,
            white 8px,
            white 20px
    );
}

.stats,
.actions,
.timer {
    display: flex;
    justify-content: center;
}

.actions {
    margin-top: 1rem;
}

.stats {
    margin: 0.5rem 0;
}

.completed {
    font-weight: 800;
}

.row {
    display: flex;
    justify-content: center;
}

.cell.wide {
    width: 8rem;
}

.cell {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 3rem;
    height: 3rem;
    border: 0.1rem solid black;
}

.cell.active {
    border: 0.2rem dashed red;
}

.box {
    background-color: orange;
}

.box::after {
    content: 'X';
}

.player {
    background-color: lightblue !important;
}

.player:after {
    content: '';
}

.target {
    background-color: yellow;
}

.target::after {
    content: 'O';
}

.wall {
    background-color: black;
}

.done {
    background-color: lightgreen;
}

.done::after {
    content: 'X';
}

.box::after,
.target::after,
.done::after {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.void {
    background-color: transparent !important;
    border: none;
    padding: 1px;
}

.danger {
    color: red;
    font-weight: 700;
}
</style>

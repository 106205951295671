<template>
  <div class="arrow-key-container">
    <div class="arrow-key up" data-key="38" @click="emitMove('up')"></div>
    <br/>
    <div class="arrow-key left" data-key="37" @click="emitMove('left')"></div>
    <div class="arrow-key down" data-key="40" @click="emitMove('down')"></div>
    <div class="arrow-key right" data-key="39" @click="emitMove('right')"></div>
  </div>
</template>
<script>
export default {
    name: 'Controls',
    methods: {
        emitMove(direction) {
            this.$emit('move-' + direction);
        },
    },
};
</script>

<style scoped>
@charset "UTF-8";
* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html {
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: 0;
    width: 100%;
}

body {
    align-content: center;
    background: #ece9e6;
    background: -webkit-linear-gradient(to left, #ece9e6, #ffffff);
    background: linear-gradient(to left, #ece9e6, #ffffff);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0;
    overflow: auto;
    padding: 0;
    width: 100%;
}

h1 {
    font-family: Helvetica, Arial;
    text-align: center;
}

.arrow-key-container {
    display: inline-block;
    text-align: center;
    transform: translate3d(0, 0, 0);
}

.arrow-key {
    align-items: center;
    background: lightblue;
    border-radius: 6px;
    box-shadow: -1px 1px 0 #999, -2px 2px 0 #999, -3px 3px 0 #999, -4px 4px 0 #999;
    color: #fff;
    display: inline-flex;
    font-size: 30px;
    font-weight: bold;
    height: 75px;
    justify-content: center;
    margin: 5px;
    transform: translate3d(0, 0, 0);
    transition: box-shadow 0.1s linear, transform 0.1s linear;
    width: 75px;
}

.arrow-key:active,
.arrow-key.press {
    box-shadow: 0px 0px 0 #999, 0px 0px 0 #999, 0px 0px 0 #999, -1px 1px 0 #999;
    transform: translate3d(-3px, 3px, 0);
}

.arrow-key.left:before {
    content: '←';
}

.arrow-key.up:before {
    content: '↑';
}

.arrow-key.down:before {
    content: '↓';
}

.arrow-key.right:before {
    content: '→';
}
</style>

import {callAPIGet, callAPIPost} from './network.js';

class SokobanRouteProvider
{
    /**
     * Returns a list of dates for which we already have Sokoban levels.
     * These dates are in the format of YYYYMMDD.
     *
     * @returns {Promise<[]>}
     */
    static async getUsedDates()
    {
        return callAPIGet(`/sokoban/dates`).then((response) => response.json());
    }

    static async saveNewLevel(date, level)
    {
        return callAPIPost(`/sokoban/levels/${date}`, {
            level: level,
        });
    }
}

export default SokobanRouteProvider;

// eslint-disable-next-line
const API_URL = process.env.VUE_APP_API_URL;

export const callAPIPost = async (endpoint, params) => {
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
        credentials: 'include', // Always include cookies in the request, even if it's across origins.  Options are: 'same-site', 'omit', 'include'
    };
    return fetch(API_URL + endpoint, options);
};

export const callAPIGet = async (endpoint) => {
    return fetch(API_URL + endpoint, {
        method: 'GET',
        credentials: 'include'
    }).then(response => {
        if (! response.ok) {
            throw new Error(response.statusMessage);
        }

        return response;
    }).catch(ex => {
        console.error(ex);

        throw ex;
    });
};

import App from './App.vue';
import {createApp} from 'vue';
import {createPinia} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueCookieComply from 'vue-cookie-comply';
import 'vue-cookie-comply/dist/style.css';
import VueGtag from 'vue-gtag';

const LOCALSTORAGE_COOKIE_COMPLY = 'cookie-comply';
const allowedCookies = ['main', 'ga'];
const localStorageCookies =
    localStorage.getItem(LOCALSTORAGE_COOKIE_COMPLY) ||
    JSON.stringify(allowedCookies);
const infoCookies =
    localStorageCookies == 'all'
        ? allowedCookies
        : JSON.parse(localStorageCookies);

//console.log(infoCookies);
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(VueCookieComply);
app.use(VueGtag, {
    config: {id: 'G-EV4Q4DY8D7'},
    enabled: infoCookies.includes('ga'),
});
app.mount('#app');

import { defineStore } from 'pinia';

export const useStopwatchStore = defineStore({
    id: 'stopwatch',
    state: () => ({
        startTime: null,
        stopTime: null,
        elapsedTime: 0,
        isRunning: false,
    }),
    getters: {
        formattedElapsedTime() {
            // Format elapsed time in minutes, seconds, and milliseconds
            const minutes = Math.floor(this.elapsedTime / 60000);
            const seconds = Math.floor((this.elapsedTime % 60000) / 1000);
            //const milliseconds = this.elapsedTime % 1000;
            return `${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}`; //.${milliseconds.toString().padStart(3, '0')}`;
        },
    },
    actions: {
        startTimer() {
            this.startTime = Date.now() - this.elapsedTime;
            this.isRunning = true;
        },
        stopTimer() {
            this.stopTime = Date.now();
            this.isRunning = false;
        },
        resetTimer() {
            this.startTime = null;
            this.stopTime = null;
            this.elapsedTime = 0;
            this.isRunning = false;
        },
        tick() {
            if (this.isRunning) {
                this.elapsedTime = Date.now() - this.startTime;
            }
        },
    },
});

<template>
  <div class="top-container">
    <div class="top-bar">Hola, <span class="username">{{ userName }}</span></div>
    <div class="container-fluid">
      <Error v-if="fatalErrorMessage !== null" :errorMessage="fatalErrorMessage" />
      <div v-if="fatalErrorMessage === null" id="game">
        <SokobanEditor v-model="level" @successfullyCompleted="handleSuccessfulCompletion"/>
      </div>
      <VueDatePicker v-if="fatalErrorMessage === null"
          v-model="date"
          teleport-center
          auto-apply
          min-date="2023-04-01"
          :hide-navigation="['time']"
          :disabled-dates="usedDates"
          :format="format"
      ></VueDatePicker>
      <button v-if="fatalErrorMessage === null" @click="handleSave">Guardar</button>
      <span v-if="errorMessage" class="danger">{{ errorMessage }}</span>
      <span v-if="successMessage" class="success">{{ successMessage }}</span>
    </div>
  </div>
</template>

<script>
import SokobanRouteProvider from "./core/sokoban-route-provider";
import DateFormatter from "./core/date-formatter";
import Auth from './core/auth';
import SokobanEditor from './components/SokobanEditor.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {onMounted, ref} from "vue";
import useProfileStore from './stores/profile';
import Error from "./components/Error.vue";

export default {
    components: {
        Error,
        SokobanEditor,
        VueDatePicker,
    },
    data() {
        return {};
    },
    setup() {
        // eslint-disable-next-line
        const apiUrl = process.env.VUE_APP_API_URL;
        // eslint-disable-next-line
        const appId = process.env.VUE_APP_ID;
        const auth = new Auth();

        const userName = ref('');
        const fatalErrorMessage = ref(null);
        const errorMessage = ref(null);
        const successMessage = ref(null);

        // Because it's asynchronous, we have to run the API call from within the onMounted hook
        const usedDates = ref([]);
        onMounted(async () => {
            const currentUser = await auth.getCurrentUser();

            if (Object.keys(currentUser).length === 0) {
                window.location.href = `${apiUrl}/auth/verify-authenticated/${appId}`;
            }

            if (currentUser.isCreator !== true) {
                fatalErrorMessage.value = 'Unauthorised to access this page, sorry!';
                return;
            }

            const profileStore = useProfileStore();
            profileStore.login(currentUser);

            userName.value = currentUser.name;

            SokobanRouteProvider.getUsedDates().then(responseData => {
                usedDates.value = responseData.map(DateFormatter.YYYYMMDDToDate);
            });
        });

        const hasCompletedSuccessfully = ref(false);
        const format = DateFormatter.formatToUK;
        const date = ref(new Date());
        const level = ref([
            ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
            ['W', 'P', ' ', ' ', ' ', ' ', ' ', ' ', 'W'],
            ['W', ' ', ' ', ' ', ' ', ' ', ' ', ' ', 'W'],
            ['W', ' ', ' ', ' ', ' ', ' ', ' ', ' ', 'W'],
            ['W', ' ', ' ', ' ', 'B', ' ', ' ', ' ', 'W'],
            ['W', ' ', ' ', ' ', ' ', ' ', ' ', ' ', 'W'],
            ['W', ' ', ' ', ' ', ' ', ' ', ' ', ' ', 'W'],
            ['W', ' ', ' ', ' ', ' ', ' ', ' ', 'T', 'W'],
            ['W', 'W', 'W', 'W', 'W', 'W', 'W', 'W', 'W'],
        ]);

        const handleSuccessfulCompletion = (value) => {
            errorMessage.value = '';
            successMessage.value = '';
            hasCompletedSuccessfully.value = value;
        }

        const handleSave = () => {
            successMessage.value = '';
            errorMessage.value = '';

            if (! hasCompletedSuccessfully.value) {
                errorMessage.value = 'Tienes que completar el juego antes de guardar';
                return;
            }

            const formattedDate = DateFormatter.dateToYYYYMMDD(date.value);

            if (usedDates.value.includes(date.value)) {
                errorMessage.value = 'Fecha invalida. Ya hay nivel para este día';
                return;
            }

            SokobanRouteProvider.saveNewLevel(
                formattedDate,
                level.value
            ).then((response) => {
                if (! response.ok) {
                    // Because it's annoyingly difficult getting the JSON from the error response.
                    // response.json() returns another Promise, so you'd have to start chaining .then()s,
                    // and you'll lose access to the original response object.
                    // There must be a way somehow but I've lost patience trying to look for it.
                    errorMessage.value = 'Se produjo un error. Por favor, rastrea la llamada de red en la consola de desarrollo para averiguar qué sucedió.';
                } else {
                    successMessage.value = 'Nivel guardado exitosamente.';
                    usedDates.value.push(date.value);
                }

            }).catch((error) => {
                errorMessage.value = error.message;
            });
        }

        return {
            format,
            usedDates,
            handleSave,
            level,
            successMessage,
            errorMessage,
            fatalErrorMessage,
            date,
            handleSuccessfulCompletion,
            userName,
        };
    },
    methods: {},
    computed: {},
};
</script>
<style>
#game {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-fluid {
    width: 90%;
    text-align: center;
}

div [aria-label="Datepicker input"] {
    max-width: 250px;
    margin: 0 auto;
}

.danger {
    color: red;
    font-weight: 700;
}

.success {
    color: green;
    font-weight: 700;
}

.top-container {
    position: relative;
    width: 100%;
}

.top-bar {
    text-align: right;
    padding: 10px;
    background-color: #ece9e6;
    position: relative;
}

.username {
    font-weight: 700;
}
</style>

<template>
  <div class="error">
    <h2>Error</h2>
    <p>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
    name: 'Error',
    props: {
        errorMessage: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.error {
    background-color: #ffdddd;
    padding: 10px;
    border: 1px solid #ff9999;
    border-radius: 4px;
    color: #ff0000;
}
</style>

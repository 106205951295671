class DateFormatter
{
    static formatToUK(date)
    {
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    static dateToYYYYMMDD(date)
    {
        return date.toISOString().slice(0, 10).replace(/-/g, '');
    }

    static YYYYMMDDToDate(str)
    {
        const year = parseInt(str[0] + str[1] + str[2] + str[3]);
        const month = parseInt(str[4] + str[5]) - 1; // months are 0-indexed in JS
        const day = parseInt(str[6] + str[7]);

        return new Date(year, month, day);
    }
}

export default DateFormatter;
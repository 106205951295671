<template>
  <div>
    <div class="row" id="loading" v-if="loading">⌛</div>
    <template v-if="!loading">
      <div class="row" v-for="(row, yIndex) in board" :key="yIndex">
        <div
            class="cell"
            v-for="(cell, xIndex) in row"
            :key="xIndex"
            :class="{
            player: xIndex === player.x && yIndex === player.y,
            box: cell === 'B',
            target:
              cell === 'T' ||
              (cell === 'D' && xIndex === player.x && yIndex === player.y), //If a box was originally on a target cell
            wall: cell === 'W',
            done: cell === 'D',
            void: cell === '.',
          }"
        ></div>
      </div>
      <div>
        <div class="actions" v-if="!completed">
          <button :disabled="!canUndo" @click.prevent="undo">&lt; Undo</button>
          <button
              :disabled="!moves || !lives"
              v-if="lives > 0"
              @click.prevent="reset"
          >
            Reset
          </button>
          <button v-if="lives === 0" @click.prevent="giveUp">Give up</button>
          <br/>
        </div>
        <div class="stats" v-if="!completed">
          ❤️ x {{ lives }} - 🏃 x {{ moves }}
        </div>
      </div>
      <Controls
          v-if="showControls && !completed"
          @move-left="moveLeft"
          @move-up="moveUp"
          @move-right="moveRight"
          @move-down="moveDown"
      />
      <div class="endgame winner" v-if="completed && !loser">
        {{ todayForEndgame }}
        <br/>
        🎊🎉 🏆 🎉🎊
        <br/>
        ❤️ x {{ lives }} - 🏃 x {{ moves }}
      </div>
      <div class="endgame loser" v-if="loser">
        {{ todayForEndgame }}
        <br/>
        🥈
        <br/>
        ❤️ x {{ lives }}
      </div>
      <div
          class="share-btn-holder"
          @click="copyToEndgameClipboard"
          v-if="completed"
      >
        <img v-if="!copied" src="/copy-icon.png" width="64" height="64"/>
        <template v-if="copied">✔️</template>
      </div>
    </template>
  </div>
</template>

<style scoped>
.winner,
.loser,
.share-btn-holder {
    text-align: center;
    margin: 1rem;
}

.share-btn-holder {
    cursor: pointer;
}
</style>

<script>
import Controls from './Controls.vue';
import {useStopwatchStore} from '../stores/stopwatch.js';

export default {
    components: {
        Controls,
    },
    name: 'Sokoban',
    props: {
        level: {
            type: Array,
            required: false,
            default: null,
        },
        showControls: {
            type: Boolean,
            default: true,
        },
        saveResult: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const timer = useStopwatchStore();

        return {
            timer,
        };
    },
    data() {
        return {
            board: [],
            player: {x: 0, y: 0},
            startPosition: {x: 0, y: 0},
            undoBoard: [],
            undoPlayer: {x: 0, y: 0},
            targets: 0,
            boxes: 0,
            boxesLeft: 0,
            rowCount: 0,
            columnCount: 0,
            lives: 0,
            moves: 0,
            canUndo: false,
            winner: false,
            loser: false,
            copied: false,
            loading: true,
        };
    },
    computed: {
        playerInTargetCell() {
            return ['T', 'D'].includes(this.level[this.player.y][this.player.x]);
        },
        completed() {
            return this.boxesLeft === 0 || this.winner || this.loser;
        },
        started() {
            return this.timer.startTime;
        },
        today() {
            const date = new Date();
            const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            return date
                .toLocaleDateString('en-GB', options)
                .split('/')
                .reverse()
                .join('-')
                .replace(/-/g, '');
        },
        todayForEndgame() {
            // FIXME: This isn't great. Would be good having a separate method returning today's date as an object.
            let dateAsString = this.today; // Example: 20230401
            const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
            let date = new Date(
                dateAsString[0] + dateAsString[1] + dateAsString[2] + dateAsString[3],
                Number(dateAsString[4] + dateAsString[5]) - 1,
                dateAsString[6] + dateAsString[7]
            );

            return date.toLocaleDateString('en-GB', options).split('/').join('-');
        },
        SOKOBAN_LIVES() {
            return 'JDD_SKBN_USR_LIVES_' + this.today;
        },
        SOKOBAN_GAME_DAY_RESULT() {
            return 'JDD_SKBN_USR_GAME_DAY_RESULT_' + this.today;
        },
    },
    watch: {
        level() {
            if (! this.completed) {
                this.loading = false;
                this.reset(false);
            }
        },
        completed(isCompleted) {
            if (isCompleted) {
                this.timer.stopTimer();

                if (! this.loser) {
                    this.winner = true;

                    this.persistResult();
                }
            }
        },
    },
    beforeMount() {
        this.reset(false);
    },
    mounted() {
        if (this.level) {
            this.loading = false;
        }
        document.addEventListener('keydown', this.handleKeyDown);
        this.lives = window.localStorage.getItem(this.SOKOBAN_LIVES) || 3;
        let gameDayResult = window.localStorage.getItem(
            this.SOKOBAN_GAME_DAY_RESULT
        );

        if (! gameDayResult) {
            return;
        }

        let gameDayResultData = JSON.parse(gameDayResult);
        this.winner = gameDayResultData.winner;
        this.loser = gameDayResultData.loser;
        this.lives = gameDayResultData.lives;
        this.moves = gameDayResultData.steps;
        this.board = gameDayResultData.board;
        this.player = gameDayResultData.player;
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    },
    methods: {
        persistLives() {
            if (! this.saveResult) {
                return;
            }

            window.localStorage.setItem(this.SOKOBAN_LIVES, this.lives);
        },
        persistResult() {
            this.$emit('successfullyCompleted', this.winner);

            if (! this.saveResult) {
                return;
            }

            window.localStorage.setItem(
                this.SOKOBAN_GAME_DAY_RESULT,
                JSON.stringify({
                    steps: this.moves,
                    lives: this.lives,
                    winner: this.winner,
                    loser: this.loser,
                    board: this.board,
                    player: this.player,
                })
            );
        },
        reset(takeLife = true) {
            if (takeLife) {
                this.lives--;
                this.persistLives();
            }
            this.canUndo = false;
            this.undoBoard = [];
            this.board = this.shallowCopy(this.level);
            this.setupGame();

            this.rowCount = this.level.length;
            this.columnCount = this.level[0].length;
            this.moves = 0;
        },
        giveUp() {
            this.loser = true;
            this.persistResult();
        },
        copyToEndgameClipboard() {
            let copyText = document.getElementsByClassName('endgame')[0];

            navigator.clipboard.writeText(
                copyText.innerText + '\n\n' + window.location.href
            );

            this.copied = true;
        },
        setupGame() {
            if (this.moves > 0) {
                this.player;
            }

            const playerRow = this.board
                .slice()
                .findIndex((row) => row.includes('P'));

            if (playerRow === -1) {
                console.error('No row found that contains P');
            }

            const playerCol = this.board[playerRow].indexOf('P');
            const done = this.level.reduce(
                (acc, row) => acc + row.filter((item) => item === 'D').length,
                0
            );

            this.targets =
                this.level.reduce(
                    (acc, row) => acc + row.filter((item) => item === 'T').length,
                    0
                ) + done;
            this.boxes =
                this.level.reduce(
                    (acc, row) => acc + row.filter((item) => item === 'B').length,
                    0
                ) + done;
            this.startPosition = {x: playerCol, y: playerRow};
            this.player = this.startPosition;
            this.checkBoxes();
        },
        undo() {
            this.canUndo = false;
            this.board = this.shallowCopy(this.undoBoard);
            this.player = this.shallowCopy(this.undoPlayer);
            this.moves++; // It still counts as move
        },
        handleKeyDown(event) {
            if (this.winner || this.loser) {
                return;
            }
            switch (event.keyCode) {
                case 37: // Left
                    this.moveLeft();
                    break;
                case 38: // Up
                    this.moveUp();
                    break;
                case 39: // Right
                    this.moveRight();
                    break;
                case 40: // Down
                    this.moveDown();
                    break;
                default:
                    return;
            }
        },
        moveLeft() {
            this.makeMove(
                this.player.x - 1,
                this.player.y,
                this.player.x - 2,
                this.player.y
            );
        },
        moveUp() {
            this.makeMove(
                this.player.x,
                this.player.y - 1,
                this.player.x,
                this.player.y - 2
            );
        },
        moveRight() {
            this.makeMove(
                this.player.x + 1,
                this.player.y,
                this.player.x + 2,
                this.player.y
            );
        },
        moveDown() {
            this.makeMove(
                this.player.x,
                this.player.y + 1,
                this.player.x,
                this.player.y + 2
            );
        },
        makeMove(nextX, nextY, afterNextX, afterNextY) {
            //Don't move if the game hasn't loaded yet
            if (this.loading) {
                return;
            }

            if (
                nextX < 0 ||
                nextY < 0 ||
                nextX >= this.columnCount ||
                nextY >= this.rowCount
            ) {
                return; //Out of bounds
            }

            let validMove = false;
            const nextCell = this.board[nextY][nextX];
            const validCells = [' ', 'B', 'T', 'D'];
            if (! validCells.includes(nextCell)) {
                return;
            }
            validMove = true;
            this.undoBoard = this.shallowCopy(this.board);
            if (
                //Move Box if the next square is a box or a box in a target
                ['B', 'D'].includes(nextCell)
            ) {
                if (this.board[afterNextY][afterNextX] === ' ') {
                    this.board[afterNextY][afterNextX] = 'B';
                } else if (
                    //Box to target
                    this.board[afterNextY][afterNextX] === 'T'
                ) {
                    this.board[afterNextY][afterNextX] = 'D';
                } else {
                    validMove = false; // Wall
                }
            }
            if (validMove) {
                this.canUndo = true;
                this.board[this.player.y][this.player.x] = this.playerInTargetCell
                    ? 'T'
                    : ' ';
                this.board[nextY][nextX] = ['T', 'D'].includes(this.board[nextY][nextX])
                    ? 'T'
                    : ' ';
                this.undoPlayer = this.shallowCopy(this.player);
                this.player.y = nextY;
                this.player.x = nextX;
                this.moves++;
                this.checkBoxes();
            }
        },
        checkBoxes() {
            this.boxesLeft = this.board.reduce(
                (acc, row) => acc + row.filter((item) => item === 'B').length,
                0
            );
        },
        startGame() {
            this.timer.startTimer();
            // Tick the timer every 10 milliseconds
            setInterval(() => {
                this.timer.tick();
            }, 1000);
        },
        shallowCopy(board) {
            return JSON.parse(JSON.stringify(board));
        },
    },
};
</script>

<style>
#loading {
    font-size: 20rem;
}

#start {
    display: flex;
    justify-content: center;
    align-items: center;
}

#start button {
    font-size: 7vmin;
    width: 20vmin;
    height: 10vmin;
    border-radius: 4vmin;
    border: 3px solid lightblue;
    color: #fff;
    font-family: inherit;
    background-color: lightblue;
    cursor: pointer;
    padding: 0 7vmin;
    font-weight: 700;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

#start button:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

#start button:hover {
    box-shadow: none;
}

.stats,
.actions,
.timer {
    display: flex;
    justify-content: center;
}

.actions {
    margin-top: 1rem;
}

.stats {
    margin: 0.5rem 0;
}

.completed {
    font-weight: 800;
}

.row {
    display: flex;
    justify-content: center;
}

.cell {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50px;
    height: 50px;
    border: 1px solid black;
}

.box {
    background-color: orange;
}

.box::after {
    content: 'X';
}

.player {
    background-color: lightblue !important;
}

.player:after {
    content: '';
}

.target {
    background-color: yellow;
}

.target::after {
    content: 'O';
}

.wall {
    background-color: black;
}

.done {
    background-color: lightgreen;
}

.done::after {
    content: 'X';
}

.box::after,
.target::after,
.done::after {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.void {
    background-color: transparent;
    border: none;
    padding: 1px;
}
</style>
